:root {
  --color-theme: #6c6;
  --color-foreground: #000;
  --color-background: #fff;
  --color-vignette: #765;
  --color-section-foreground: #fff;
}
html,
body,
.slides,
article,
article:before,
article:after {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}
body {
  margin: 0;
  padding: 0;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 500;
  user-select: none;
}
.slides > article:before {
  background-image: url("46d11986dd22a33114164d1e6cd1e1c9.jpg");
}
.slides {
  display: table;
}
.slide-area {
  height: 100vh;
  width: 10vw;
  position: absolute;
}
#prev-slide-area {
  left: 0;
}
#prev-slide-area:hover {
  background: linear-gradient(90deg, var(--color-theme) 0, rgba(255,255,255,0) 10%);
}
#next-slide-area {
  right: 0;
}
#next-slide-area:hover {
  background: linear-gradient(270deg, var(--color-theme) 0, rgba(255,255,255,0) 10%);
}
article {
  vertical-align: middle;
  display: none;
}
article.current {
  display: table-cell;
}
article:before,
article:after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}
article:before {
  z-index: -2;
  filter: grayscale(100%) contrast(160%);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top left;
}
article:after {
  z-index: -1;
  opacity: 0.95;
  background-image: radial-gradient(75vw, var(--color-background) 0%, var(--color-background) 75%, var(--color-vignette) 100%);
}
article.plain:before,
article.plain:after {
  display: none;
}
article.section:after {
  background-image: radial-gradient(75vw, var(--color-theme) 0%, var(--color-theme) 50%, var(--color-vignette) 100%);
}
article.section h1,
article.section h2,
article.section h3,
article.section h4,
article.section h5,
article.section h6 {
  color: var(--color-section-foreground);
}
article.image {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}
article.image.cover {
  background-size: cover;
}
article.image > *:not(:only-child) {
  position: absolute;
  z-index: 2;
  text-align: center;
  width: 100%;
}
article.image > *:not(:only-child):first-child {
  top: 0;
}
article.image > *:not(:only-child):last-child {
  bottom: 0;
}
.build > * {
  transition: opacity 0.2s ease-in-out 0.2s;
}
.to-build {
  opacity: 0;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  text-align: center;
  color: var(--color-theme);
  text-shadow: 0 0 1px #000;
  margin: 1vh;
}
em,
a,
a:visited,
a:hover {
  color: var(--color-theme);
}
h1 {
  font-size: 10vw;
}
h2 {
  font-size: 5vw;
}
h3 {
  font-size: 2.5vw;
}
ul {
  list-style: none;
  font-size: 3vw;
  line-height: 2em;
  padding-left: 10vw;
}
video,
img {
  width: auto;
  height: auto;
  max-width: 80vw;
  max-height: 80vh;
  display: block;
  margin: 0 auto;
}
.slides pre.prettyprint {
  background-color: #000;
  border-radius: 8px;
  width: 90%;
  font-size: 3vw;
  margin: 0 auto;
  padding: 1vw 2vw;
  white-space: pre-wrap;
  line-height: 3.5vw;
/* string  - green */
/* keyword - dark pink */
/* comment - gray */
/* type - light blue */
/* literal - blue */
/* punctuation - white */
/* plaintext - white */
/* html/xml tag    - light blue */
/* html/xml attribute name  - khaki */
/* html/xml attribute value - green */
/* decimal - blue */
}
.slides pre.prettyprint.compact {
  line-height: 3vw;
}
.slides pre.prettyprint .str {
  color: #65b042;
}
.slides pre.prettyprint .kwd {
  color: #e28964;
}
.slides pre.prettyprint .com {
  color: #aeaeae;
  font-style: italic;
}
.slides pre.prettyprint .typ {
  color: #89bdff;
}
.slides pre.prettyprint .lit {
  color: #3387cc;
}
.slides pre.prettyprint .pun {
  color: #fff;
}
.slides pre.prettyprint .pln {
  color: #fff;
}
.slides pre.prettyprint .tag {
  color: #89bdff;
}
.slides pre.prettyprint .atn {
  color: #bdb76b;
}
.slides pre.prettyprint .atv {
  color: #65b042;
}
.slides pre.prettyprint .dec {
  color: #3387cc;
}
.slides .linenums ol {
  margin: 0;
  padding: 0;
  list-style: none;
}
.slides .linenums ol li {
  background: #000;
}
.slides .linenums.h0 li.L0,
.slides .linenums.h1 li.L1,
.slides .linenums.h2 li.L2,
.slides .linenums.h3 li.L3,
.slides .linenums.h4 li.L4,
.slides .linenums.h5 li.L5,
.slides .linenums.h6 li.L6,
.slides .linenums.h7 li.L7,
.slides .linenums.h8 li.L8,
.slides .linenums.h9 li.L9 {
  background: rgba(102,204,102,0.4);
}
ul.horizontal {
  margin: 0;
  padding: 0;
  display: flex;
  width: 100%;
}
ul.horizontal h1,
ul.horizontal h2 {
  line-height: 2em;
}
ul.horizontal ul {
  padding: 0;
}
ul.horizontal li {
  padding: 1vw;
  width: 100%;
  text-align: center;
  font-size: 7.5vh;
  line-height: 1.5em;
}
:root {
  --color-theme: #6c6;
  --color-foreground: #000;
  --color-background: #fff;
  --color-vignette: #765;
  --color-section-foreground: #fff;
}
ul.horizontal {
  align-items: center;
}
ul.horizontal pre {
  text-align: left;
}
